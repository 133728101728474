import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { msalApp } from '../../auth/authUtils';
import { UserContextType } from './@types/user';
import { UserContext } from './UserContext';

export default function EditUserProfileDialog() {
  const { user, updateMyUserProfile, updateUser } = React.useContext(UserContext) as UserContextType;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: { target: { name: string, value: string }}) => {
		updateUser(event.target.name, event.target.value)
  };

  const handleSave = () => {
    updateMyUserProfile(user);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleDelete = () => {
  //   deleteUser(user);
  //   setOpen(false);
  // };

  // const handleMarkDeleted = () => {
  //   markUserDeleted(user);
  //   setOpen(false);
  // };

  const logout = () => {
    msalApp.logout();
  };

  return (
    <div>
      <button className="userProfileButton" onClick={handleClickOpen} >{user.firstName.charAt(0) + user.lastName.charAt(0)}</button>
      <Dialog
        fullScreen={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">Edit User Profile</DialogTitle>
        {/* {
          checkIfDev() &&
          <DialogActions style={{justifyContent: "space-between", marginBottom: "10px"}}>
            <Button className='button proceed' onClick={handleMarkDeleted}>MARK DELETED</Button>
            <Button className='button proceed' onClick={handleDelete}>!PERMANENT DELETE!</Button>
          </DialogActions>
        } */}
        <DialogContent>
          <TextField
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={user.email}
            disabled
            style={{marginBottom: "16px"}}
          />
          <TextField
            id="firstname"
            name="firstName"
            label="First Name"
            type="name"
            fullWidth
            variant="outlined"
            value={user.firstName}
            onChange={handleChange}
            style={{marginBottom: "16px"}}
          />
          <TextField
            id="lastname"
            name="lastName"
            label="Last Name"
            type="name"
            fullWidth
            variant="outlined"
            value={user.lastName}
            onChange={handleChange}
            style={{marginBottom: "10px"}}
          />
        </DialogContent>
        <DialogActions>
          <div style={{width: "100%"}}>
            <Button className='button proceed' onClick={logout}>Logout</Button>
          </div>
          <Button className='button' onClick={handleClose}>Cancel</Button>
          <Button className='button proceed' onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
