import { Button } from '@material-ui/core';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import axios from 'axios';
import { Component } from "react";
import * as React from 'react';
import { API_KEY, HALT_PATH, RESTART_PATH, STATUS_PATH } from '../../ApplicationConfiguration';
import ErrorBox from '../errorbox/ErrorBox';
import StatusBox from '../statusbox/StatusBox';
import './QueueListener.css';
import { checkIfDev } from '../../auth/AccessControlFunctions';


interface IProps {
    id: string;
    title: string;
    listenerUrl: string;
    queueUrl: string;
}

class QueueListener extends Component <IProps, {}> {

    public state = {
        dlqColor: "#00cc00",
        dlqCount: "?",
        haltButtonDisable: true,
        qColor: "#00cc00",
        qCount: "?",    
        responseCode: 0,
        responseMessage: 'Unknown',
        restartButtonDisable: true,
        state: "red",
        statusCode: 1,
        statusMessage: 'unknown',
        timer:  setInterval(()=> this.getStatus(), 10000),
        timerForCount: setInterval(()=> this.getQueueStatus(), 10000)
    };
    
    private badgeOkColor = "#00cc00";
    private badgeWarningColor = "#ff9900";
    private badgeErrorColor = "#ff3300";
  
    constructor(props: IProps) {
        super(props);

        this.haltListener = this.haltListener.bind(this);
        this.restartListener = this.restartListener.bind(this);

        this.getStatus();

        this.getQueueStatus();
 
        this.state.qColor = this.badgeWarningColor;
        this.state.dlqColor = this.badgeWarningColor;
    
    }
 
  public render() {

    const badge1Color = this.state.qColor;
    const badge2Color = this.state.dlqColor;

    const badge1Style = { container: { color:"black", backgroundColor:badge1Color, borderRadius:"15px", border:"1px solid", padding:"5px" }}
    
    const badge2Style = { container: { color:"black", backgroundColor:badge2Color, borderRadius:"15px", border:"1px solid", padding:"5px" }}

    const qAdminUrl = "https://qadmin.cimacorpapi.com/admin/browse.jsp?JMSDestination=" + this.props.id;
    const qDlqAdminUrl = "https://qadmin.cimacorpapi.com/admin/browse.jsp?JMSDestination=" + this.props.id + ".DLQ";
    
    return (
      <div className='queueListener'>
        <div className='queueListenerColumn' style={{width: "100%", maxWidth: "600px"}}>
          <h2>{this.props.title}</h2>
        </div>      
        <div className='queueListenerColumn' style={{width: "140px"}}>
          <div style={{position:"relative", display: "inline-block"}}>
            <a href={qAdminUrl} rel="noopener noreferrer"><div style={{position:"absolute", top:"-10px", left:"-10px", minWidth:"30px", textAlign:"center"}}><div style={badge1Style.container}>{this.state.qCount}</div></div></a>
            <a href={qDlqAdminUrl} rel="noopener noreferrer"><div style={{position:"absolute", top:"-10px", right:"-10px", minWidth:"30px", textAlign:"center"}}><div style={badge2Style.container}>{this.state.dlqCount}</div></div></a>
            <StatusBox code={this.state.statusCode} message={this.state.statusMessage}/>      
          </div>
        </div>
        <div className='queueListenerColumn'>
          <Button variant="contained" color="primary" onClick={this.haltListener} disabled={this.state.haltButtonDisable} >
            <PauseCircleOutline /> <span style={{paddingLeft: "10px"}}>Halt</span>
          </Button>
        </div>
        <div className='queueListenerColumn'>
          <Button variant="contained" color="primary" onClick={this.restartListener} disabled={this.state.restartButtonDisable} >
            <PlayCircleOutline /> <span style={{paddingLeft: "10px"}}>Restart</span>
          </Button>
        </div>
        <div className='queueListenerColumn' style={{width: "100%", maxWidth: "220px"}}>
          <ErrorBox code={this.state.responseCode} message={this.state.responseMessage}/>
        </div>
      </div>
    );
  }

    public componentDidMount() {
        const timer = setInterval(()=> this.getStatus(), 300000); // every five minutes
        this.setState({timer});
        const timerForCount = setInterval(()=> this.getQueueStatus(), 300000); // every five minutes
        this.setState({timerForCount});
        
    }
      
    public componentWillUnmount() {
        clearInterval(this.state.timer);
        clearInterval(this.state.timerForCount);
    }

    private haltListener() {
        if(checkIfDev()) {
            return axios.get(this.props.listenerUrl + HALT_PATH, {
                headers : {
                    'Access-Control-Allow-Origin' : '*',
                    // tslint:disable-next-line:object-literal-sort-keys
                    'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Methods': 'GET',
                    'Access-Control-Allow-Headers': 'x-api-key',
                    'Content-Type': 'application/json',
                    "x-api-key": API_KEY
                }
            }).then(response => {
                this.updateDisplay(response);
            })
            .catch((error) => {
                console.log(error);
                return Promise.resolve(error.response);
            });
        }
    }

    private restartListener() {
        if(checkIfDev()) {
            return axios.get(this.props.listenerUrl + RESTART_PATH, {
                headers : {
                    'Access-Control-Allow-Origin' : '*',
                    // tslint:disable-next-line:object-literal-sort-keys
                    'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Methods': 'GET',
                    'Access-Control-Allow-Headers': 'x-api-key',
                    'Content-Type': 'application/json',
                    "x-api-key": API_KEY
                }
            }).then(response => {
                this.updateDisplay(response);
            })
            .catch((error) => {
                console.log(error);
                return Promise.resolve(error.response);
            });
        }
    }
      
    private  getStatus() {
        return axios.get(this.props.listenerUrl + STATUS_PATH, {
            headers : {
                'Access-Control-Allow-Origin' : '*',
                // tslint:disable-next-line:object-literal-sort-keys
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Methods': 'GET',
                'Access-Control-Allow-Headers': 'x-api-key',
                'Content-Type': 'application/json',
                "x-api-key": API_KEY
            }
        }).then(response => {
            this.updateDisplay(response);
        })
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error.response);
        });
     
    }

    private getQueueStatus() {
        const QUEUE_STATUS_PATH = "/queue/messageCount";
        console.log("in getQueueStatus() for: " + this.props.listenerUrl);
        return axios.get(this.props.listenerUrl + QUEUE_STATUS_PATH, {
            headers : {
                'Access-Control-Allow-Origin' : '*',
                // tslint:disable-next-line:object-literal-sort-keys
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Methods': 'GET',
                'Content-Type': 'application/json',
                'x-api-key': API_KEY
            }
        }).then(response => {
            console.log("queue URL: " + this.props.listenerUrl);
            console.log("Response: " + response.data);
            this.updateQueueBadgeDisplays(response);
        })
        .catch((error) => {
            console.log("queue URL error: " + this.props.listenerUrl);
            console.log(error);
            return Promise.resolve(error.response);
        });

    }


    private updateDisplay(response: any) {
        // console.log(response.status);
        // console.log(response.data);
        const responseCode = response.status;
        this.setState({responseCode});
        const responseMessage = responseCode === 200 ? "Successful" : "Fail";
        this.setState({responseMessage});

        const statusMessage = response.data.status;
        const statusCode = statusMessage === 'running' ? 0 : 2;
        this.setState({statusMessage});
        this.setState({statusCode});

        const haltButtonDisable = statusMessage === 'running' ? false : true;
        this.setState({haltButtonDisable});
        const restartButtonDisable = statusMessage === 'stopped' ? false : true;
        this.setState({restartButtonDisable});

        return Promise.resolve(response);
    }

    private updateQueueBadgeDisplays(response: any) {
        console.log(response);
 
        const responseCode = response.status;
        if(responseCode === 200) {
            const qCount = response.data.count;
            const dlqCount = response.data.dlqCount;
            this.setState({qCount});
            this.setState({dlqCount});

            if(qCount === 0) {
                const qColor = this.badgeOkColor;
                this.setState({qColor});
            }
            else {
                const qColor = this.badgeWarningColor;
                this.setState({qColor});
            }
            if(dlqCount === 0) {
                const dlqColor = this.badgeOkColor;
                this.setState({dlqColor});
            }
            else {
                const dlqColor = this.badgeWarningColor;
                this.setState({dlqColor});
            }
        }
        else {
            const qCount = responseCode;
            this.setState({qCount});
            const qColor = this.badgeErrorColor;
            this.setState({qColor});
            const dlqCount = responseCode;
            this.setState({dlqCount});
            const dlqColor = this.badgeErrorColor;
            this.setState({dlqColor});
        }
    }   

}

export default QueueListener;