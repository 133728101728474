import ReactDOM from 'react-dom';
import './index.css';
import Login from './displays/login/Login';
import * as serviceWorker from './serviceWorker';
import { ApplicationContext } from './objects/ApplicationContext';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import Dashboard from './displays/dashboard/Dashboard';
import { Component } from 'react';
import { acquireToken } from './auth/AuthServices';

var applicationContext: ApplicationContext = ApplicationContext.getInstance();

acquireToken();

class ProtectedRoute extends Component {
  render() {
    //@ts-ignore
    const { component: Component, ...props } = this.props

    return (
      <Route 
        {...props} 
        render={props => (
          applicationContext.loggedIn ?
            <Component {...props} /> :
            <Redirect to='/' />
        )} 
      />
    )
  }
}

ReactDOM.render(
	<Router>
		<div>
			<Route exact={true} path="/" component={Login} />
      <Route exact={true} path="/login"><Redirect to="/" /></Route>
      {//@ts-ignore
      <ProtectedRoute exact={true} path="/dashboard" component={Dashboard} />}
		</div>
	</Router>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
