import * as React from 'react';

const PackageJSON = require('../../../package.json');

class Footer extends React.Component {

	public render() {
		return(
			<footer>
				<small>Copyright © {new Date().getFullYear()} Alta Cima Corp. All Rights Reserved.</small>
				<small>Version {PackageJSON.version}</small>
			</footer>
		);
	}
}

export default Footer;