import * as React from 'react';
import './ErrorBox.css';

interface IProps {
	code: number;
	message: string;
}

class ErrorBox extends React.Component<IProps, {}> {
	// private errorStyle = {
	// 	backgroundColor: "#f2dede"
	// }
	private errorStyle = {
		backgroundColor: "#ff8888",
	}
	/*private warningStyle = {
		backgroundColor: "#fcf8e3"
	}*/
	// private successStyle = {
	// 	backgroundColor: "#dff0d8"
	// }
	private successStyle = {
		backgroundColor: "#aaffaa"
	}

	public render() {
		if(this.props.code >= 200 && this.props.code < 300) {
			return(
				<div className="errorBox" style={this.successStyle}>
					<h4>{ this.props.code } : { this.props.message } </h4>
				</div>
			)
		} else {
			return(
				<div className="errorBox" style={this.errorStyle}>
					<h4>Error { this.props.code } : { this.props.message } </h4>
				</div>
			)
		}
		
	}
}

export default ErrorBox;