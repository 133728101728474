import EditUserProfileDialog from '../../objects/user/EditUserProfileDialog';
import React from 'react';
import { BG_COLOR } from '../../ApplicationConfiguration';

const Navbar = (props: any) => {

  return (
    <>
      <div className="navbar" style={{backgroundColor: BG_COLOR}}>
        <div className="navbarLogoContainer" style={{maxWidth: "100%"}}>
          <h2 style={{fontSize: 26, fontWeight: "400", cursor: "pointer"}} onClick={() => props.changeTab(0)}>Cimacorp Queue Listener Controls</h2>
        </div>
        <div className="navbarProfileContainer">
          <EditUserProfileDialog />
        </div>
      </div>
    </>
  );
};

export default Navbar;