import * as React from 'react';
import './StatusBox.css';

interface IProps {
	code: number;
	message: string;
}

class StatusBox extends React.Component<IProps, {}> {
	private errorStyle = {
		backgroundColor: "#ff3300"
	}
	private warningStyle = {
		backgroundColor: "#ff9900"
	}
	private successStyle = {
		backgroundColor: "#00cc00"
	}

	public render() {
		if(this.props.code === 0 ) {
			return(
				<div className="statusBox" style={this.successStyle}>
					{ this.props.message }
				</div>
			)
        } else if( this.props.code === 1 ) {
			return(
				<div className="statusBox" style={this.warningStyle}>
					{ this.props.message }
				</div>
			)
        } else {
			return(
				<div className="statusBox" style={this.errorStyle}>
					{ this.props.message }
				</div>
			)
		}
		
	}
}

export default StatusBox;