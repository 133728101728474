
export const USE_DEV_PATH = false;

export const PATH = USE_DEV_PATH ? 'https://dev-v2api.nmhiccrm.com' : 'https://v2api.nmhiccrm.com';
export const QUEUE_CONSUMER_PATH = USE_DEV_PATH ? 'https://jms.cimacorpapi.com/dev-ins-queue-consumer' : 'https://jms.cimacorpapi.com/ins-queue-consumer';
export const QUEUE_API_KEY = "3e72b987-eed9-4ac9-a329-be16be403cad";
export const BG_COLOR = USE_DEV_PATH ? 'var(--dev)' : 'var(--darkblue)';
export const TEXT_COLOR = USE_DEV_PATH ? 'var(--dev)' : 'white';
export const STATUS_PATH = "/listener/status";
export const HALT_PATH = "/listener/halt";
export const RESTART_PATH = "/listener/restart";
export const API_KEY = "3e72b987-eed9-4ac9-a329-be16be403cad";